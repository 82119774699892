
<template>
  <v-menu
    transition="scale-transition"
    offset-y
  >
    <template #activator="{ attrs, on }">
      <v-btn
        v-if="iconed"
        icon
        :color="color || 'blue-grey'"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
      <v-btn
        v-else
        :text="texted"
        :dark="!texted"
        :color="texted ? '' : color || 'blue-grey'"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon
          v-if="prefixIcon"
          left
          :dark="!texted"
        >
          {{ prefixIcon }}
        </v-icon>
        {{ text }}
        <v-icon
          right
          :dark="!texted"
        >
          {{ icon }}
        </v-icon>
      </v-btn>
    </template>
    <v-list
      dense
    >
      <v-list-item
        v-for="(act, index) in actions"
        :key="`act_${index}`"
        link
        :disabled="act.disabled || false"
        @click="onAction(index, act)"
      >
        <v-list-item-icon v-if="act.icon">
          <v-icon>{{ act.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ act.text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
  export default {
    name: 'PopMenu',
    props: {
      name: {
        type: String,
        default: ''
      },
      color: {
        type: String,
        default: 'blue-grey'
      },
      icon: {
        type: String,
        default: 'mdi-chevron-down'
      },
      iconed: {
        type: Boolean,
        default: true
      },
      prefixIcon: {
        type: String,
        default: ''
      },
      text: {
        type: String,
        default: ''
      },
      texted: {
        type: Boolean,
        default: true
      },
      actions: {
        type: Array,
        default: () => {
          return []
        }
      }
    },
    methods: {
      onAction (index, item) {
        this.$emit('action', {
          name: this.name,
          index,
          item
        })
      },
    }
  }
</script>
